import React from "react";
import "./About.css";
import People from "../../utils/Images/Hero Section 1 (1).png";

function About() {

  return (
    <>
      <div className="container1">
        <div className="sub-container">
          <div className="image-container">
            <img src={People} alt="People" />
          </div>

          <div className="main-about">
            <div className="global-about">
              <div>
                <p className="about">About Us</p>
                <p className="global">
                  Welcome to  Axia Kairos
                </p>
              </div>
              <p className="raigad">
                Empowering businesses with innovative digital solutions, we specialize in transforming complex challenges into seamless, scalable outcomes.
                With a commitment to global excellence, our comprehensive services ranging from advanced technology integration to data-driven insights drive operational
                success and enable businesses to thrive in a competitive landscape.
              </p>
              <p className="raigad">
                Our client-centered approach and expertise in emerging technologies ensure each solution aligns with strategic goals, fostering long-term growth and resilience.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
