import React, { useEffect } from "react";
import "./Navbar.css";
import People from "../../utils/Images/male-female-business-people-working-tablet-office 1.png";
import Services from "./Services";
import About from "./About";
import Navbar from "../Navbar/Navbar";
import { Background } from "../Background";
import Footer from '../Footer/Footer'


const NavBar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
  return (
    <>
      <div className="main">
        <Background color="white">
          <Navbar />
          {/* <div className="main-div-1">
            <div className="flex-display-1-1">
              <p className="raigad-head"> Digital Solutions <br>
              </br>for Future-Ready 
              <br/>Businesses</p>
              <div className="sub-div">
                <p className="career-2-3">
                Innovate. Transform. Lead.
                </p>
              </div>
            </div>
            <div className="image-container">
              <img src={People} alt="People" />
            </div>
          </div> */}
          <div className="main-div-1">
  <div className="flex-display-1-1">
    <p className="raigad-head">
  Generate Business Value and Accelerated<br/> Growth with our Digital 
Transformation Experts    </p>
    <div className="sub-div">
      <p className="career-2-3">
        Innovate. Transform. Lead.
      </p>
    </div>
  </div>
  <div className="image-container">
    <img src={People} alt="People" />
  </div>
</div>

        </Background>
      </div>
      <About/>
      <Services/>
      <Footer/>
    </>
  );
};

export default NavBar;