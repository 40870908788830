import React from "react";
import Location from '../../utils/Icons/Location.svg';
import Phone from '../../utils/Icons/Phone.svg';
import Mail from '../../utils/Icons/Mail.svg';
import AxiaWhiteLogo from '../../utils/Icons/AxiaWhiteLogo.png';
import "./Footer.css";

const Footer = () => {

  return (
    <>
      <div style={{ background: "#000000" }}>
        <div className="main-footer">
          <div className="footer-section">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <img src={AxiaWhiteLogo} alt="Logo"></img>
            </div>
            <div className="right-footer">
              <div className="first-sec">
                <h6 className="quick">Contact us</h6>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px", textAlign: "left" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={Location} alt="Address Icon" className="footer-icon" />
                    <p className="links" style={{ marginLeft: "10px" }}>Address: 34 Minowan Miikan Lane, Toronto, ON, M6J0G3, Canada​</p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={Phone} alt="Phone Icon" className="footer-icon" />
                    <p className="links" style={{ marginLeft: "10px" }}>+1 437-702-2997</p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={Mail} alt="Email Icon" className="footer-icon" />
                    <p className="links" style={{ marginLeft: "10px" }}>contactus@axiakairos.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right">
          <p className="copyright-text">
            {'Copyright 2024 © All Rights Reserved by AxiaKairos'}
          </p>
        </div>
      </div>
    </>
  );
};
export default Footer;
