import React from "react";
import "./services.css";
import { Card, Grid } from "@mui/material";
import Web from "../../utils/Icons/Group.svg";
import Content from '../../utils/Icons/Contentdev.svg';
import Mobile from '../../utils/Icons/mobiledev.svg';
import Design from '../../utils/Icons/Designdev.svg';
import SMO from '../../utils/Icons/Smodev.svg';
import sales from '../../utils/Icons/salesdev.svg';
import Search from '../../utils/Icons/searchdev.svg';
import Robotic from '../../utils/Icons/roboticdev.svg';
import Cyber from '../../utils/Icons/Cyber.svg';

function Services() {
  

  const services = [
    { title: "Digital<br/> Transformation", image: Web },
    { title: "Devops &<br/> Cloud Engineering", image: Mobile },
    { title: "Mobility &<br/> Web", image: Design },
    { title: "E - Commerce", image: Content },
    { title: "Data Warehouse &<br/> Big Data", image: SMO },
    { title: "AI, Blockchain &<br/> IoT", image: sales },
    { title: "RPA, SQA &<br/> Testing", image: Search },
    { title: "IT Infrastructure<br/> Services", image: Robotic },
    { title: "Cyber Security", image: Cyber }
  ];

  return (
    <div className="services">
      <div className="heading">
        <p className="service-heading">Our core services</p>

        <p className="heading-caption">
        We offer tailored technology solutions that enable businesses of all sizes to achieve their digital transformation goals. Our services are designed to drive business success, maximize profitability, and establish industry leadership.
        </p>
      </div>
      <Grid container spacing={3} className="card-container" justifyContent="center" alignItems="center">
  {services.map((service, index) => (
    <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
      <Card className="service-card" sx={{ borderRadius: "10px" }}>
        <div className="card1">
          <img src={service.image} alt={service.title} width="50px" height="50px" />
          <p className="font-web" dangerouslySetInnerHTML={{ __html: service.title }} />
        </div>
      </Card>
    </Grid>
  ))}
</Grid>



    </div>
  );
}

export default Services;
