import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";
import Raigad from "../../utils/Images/AxiaLogo.svg";
import { useBackgroundColor } from "../Background";
import "./Navbar.css";

function Navbar() {
 
  const backgroundColor = useBackgroundColor();
  const nav = useNavigate()
  const handleLogoClick = ()=>{
    nav('/')
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          boxShadow: "none",
          background: backgroundColor || "white",
          position: "relative",
          paddingTop: "20px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center"
            }}
          >
            <img src={Raigad} alt="Large Logo" className="image-styles" onClick={handleLogoClick}/>
          </Box>
       
        </Toolbar>
      </AppBar>
    </Box>
  );
}

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
